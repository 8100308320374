import "wicg-inert";
import { NavbarToggle } from "./modules/navbar-toggle";
import { SlideToggle } from "./modules/slide-toggle";
import { mobileWidth } from "./modules/utility";

// Init all the slide downs
const slideToggle = new SlideToggle();
let mobileNavbar: NavbarToggle;

// Set the true if mobile nav has run. That way we can stop checking
let mobileNavSetup = false;

/**
 * Setup the mobile nav if the width matches
 */
function setupMobileNav() {
    const navbarMenu$ = $('.navbar-nav');

    if (mobileWidth.matches) {
        // This should only run the first time things are set up
        if (!mobileNavSetup) {
            mobileNavbar = new NavbarToggle();

            navbarMenu$.on("nav-opened", () => slideToggle.closeAll()); // Close search wrap
        }

        // Set this later so there isn't a flash of menu on resize
        navbarMenu$.addClass("navbar-transition");

        // Manually set inert if on mobile
        mobileNavbar.setInert();

        mobileNavSetup = true;
    }

    // This should only run if the mobile nav has been set up at some point
    if (!mobileWidth.matches && mobileNavSetup) {
        navbarMenu$.removeClass("navbar-transition");
        mobileNavbar.unsetInert();
    }
}

const delay = 100;
let resizeTaskId: NodeJS.Timeout | null = null;

/**
 * Use this to debounce the resize check
 */
function debounceMobileNav() {
    if (resizeTaskId !== null) {
        clearTimeout(resizeTaskId);
    }

    resizeTaskId = setTimeout(() => {
        resizeTaskId = null;
        setupMobileNav();
    }, delay);
}

setupMobileNav();

// Make sure the mobile works on resize
window.addEventListener("resize", debounceMobileNav, { passive: true });
