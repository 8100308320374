import { requestIdleCallbackShim } from "./utility";

/**
 * Allows elements to animate in from the top of the screen.
 * Mostly used in the navbar, but can be used anywhere if things should come from the top.
 *
 * There needs to be an initiator (ex: button) and then the slidey element.
 * The initiator should have a class of 'js-slide-control' and the slidey should have a class of slide-element.
 */
export class SlideToggle {
  private slideToggles$ = $(".js-slide-control");
  private slideTarget$: JQuery<HTMLElement> | null = null;
  private currentToggle: HTMLElement | null = null;

  constructor() {
    this.handleClose = this.handleClose.bind(this);

    this.attachSlide();
  }

  /**
   * Close all dem slides
   */
  public closeAll() {
    this.slideToggles$.each((_, slideToggle) => {

      const target = $(`#${slideToggle.dataset.slideTarget}`);

      this.slideClose(target, slideToggle);
    })
  }

  /**
   * Attach the event to the initiator element.
   */
  private attachSlide() {
    this.slideToggles$.each((_, toggle) => {
      toggle.addEventListener("click", () => {
        this.currentToggle = toggle;
        this.determineSlide();
      });
    })

  }

  /**
   * Determine whether the element needs to slide up or down.
   */
  private determineSlide() {
    this.slideTarget$ = $(`#${this.currentToggle?.dataset.slideTarget}`);

    if (this.slideTarget$.hasClass("slide-open")) {
      this.slideClose();
    } else {
      this.slideOpen();
    }
  }

  /**
   * Slide the element down and set the aria-expanded to true.
   */
  private slideOpen() {
    if (this.slideTarget$) {
      // Emit an event specific to the slidey target
      this.slideTarget$.trigger('slide-opening');

      this.closeOthers();
      this.slideTarget$.addClass("slide-open");
      this.currentToggle!.setAttribute("aria-expanded", "true");
      this.attachClose();

      this.slideTarget$.trigger('slide-opened');

      this.unsetInert(this.slideTarget$);
    }
  }

  /**
   * Closes the slide element. Takes optional target and toggle options. Useful when closing from external trigger.
   * @param slideTarget HTMLElement | null
   * @param currentToggle HTMLElement | null
   */
  private slideClose(slideTarget = this.slideTarget$, currentToggle = this.currentToggle) {
    if (slideTarget) {
      slideTarget.trigger("slide-closing");

      slideTarget.removeClass("slide-open");
      currentToggle!.setAttribute("aria-expanded", "false");
      this.removeClose();

      slideTarget.trigger("slide-closed");

      this.setInert(slideTarget);
    }
  }

  /**
   * Only allow one slidey thing to be slided down. Calls slide up to handle sliding everything up.
   */
  private closeOthers() {
    // Call the element that is currently open.
    const slideTarget$ = $(".slide-open");

    if (slideTarget$?.length > 0) {
      // Grab the id of the open element and grab the initiator element based on the data attribute.
      const slideId = slideTarget$.prop('id');
      const currentToggles$ = $<HTMLElement>(`.js-slide-control[data-slide-target="${slideId}"]`);

      // Make sure all the aria-expanded attributes are set to false. Lookin at you account icon.
      currentToggles$.each((_, currentToggle) => {
        this.slideClose(slideTarget$, currentToggle);
      })
    }
  }

  /**
   * The slide elements can also be closed by clicking on the body or using the esc key. This handles that logic
   */
  private handleClose(e: JQuery.Event) {
    const slideTarget$ = $(".slide-open");
    const slideId = slideTarget$.attr("id");
    const currentToggles$ = $<HTMLElement>(`.js-slide-control[data-slide-target="${slideId}"]`);

    if (e.type === "click") {
      currentToggles$.each((_, currentToggle) => {
        this.slideClose(slideTarget$, currentToggle);
      });
    }

    if (e.key === 'Enter') {
      currentToggles$.each((_, currentToggle) => {
        this.slideClose(slideTarget$, currentToggle);
      })
    }
  }

  /**
   * Set the inert attribute to true. This hides the dropdowns from screen readers
   */
  private setInert(slideTarget: JQuery<HTMLElement>) {
    if (!Element.prototype.matches) {
      Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
    }

    requestIdleCallbackShim();

    (window as any).requestIdleCallback(() => slideTarget.attr("inert", "true"));
  }

  /**
   * Set the inert attribute to false. This shows the dropdown in screen readers.
   */
  private unsetInert(slideTarget: JQuery<HTMLElement>) {
    if (!Element.prototype.matches) {
      Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
    }

    requestIdleCallbackShim();

    (window as any).requestIdleCallback(() => slideTarget.removeAttr('inert'));
  }

  /**
   * Attach the extra close event handlers
   */
  private attachClose() {
    $('main').on('click', this.handleClose);
    $(document.body).on('keyup', this.handleClose);
  }

  /**
   * Clean up and remove the event handlers once we're done
   */
  private removeClose() {
    $('main').off('click', this.handleClose);
    $(document.body).off('keyup', this.handleClose);
  }
}
