import { requestIdleCallbackShim } from "./utility";

/**
 * The mobile nav menu. Just adds a class and changes the aria attribute.
 * Fairly simple
 */
export class NavbarToggle {
  private navButton$ = $(".hamburger");
  private navMenu$ = $(".navbar-nav");

  constructor() {
    if (this.navButton$.length) {
      this.navButton$.on("click", () => {
        if (!this.navMenu$.hasClass('nav-open')) {
          this.navMenu$.addClass('nav-open');
        } else {
          this.navMenu$.removeClass('nav-open');
        }

        this.handleToggle();
      });
    }
  }

  /**
   * Manually close the navbar. This can be used anywhere
   */
  public closeNavbar() {
    if (this.navMenu$.length && this.navMenu$.hasClass("nav-open")) {
      this.navMenu$.removeClass("nav-open");
      this.handleToggle(true);
    }
  }

  /**
   * Set the inert attribute to true. This hides the menu from screen readers
   */
  public setInert() {
    if (!Element.prototype.matches) {
      Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
    }

    requestIdleCallbackShim();

    (window as any).requestIdleCallback(() => {
      this.navMenu$.prop('inert', 'true')
    });
  }

  /**
   * Set the inert attribute to false. This shows the menu in screen readers.
   */
  public unsetInert() {
    if (!Element.prototype.matches) {
      Element.prototype.matches = (Element.prototype as any).msMatchesSelector;
    }

    requestIdleCallbackShim();

    (window as any).requestIdleCallback(() => {
      this.navMenu$.removeAttr('inert');
    });
  }

  /**
   * Set the aria-expanded
   * @param collapse boolean
   */
  private handleToggle(collapse = false) {
    if (collapse || this.navButton$.attr("aria-expanded") === "true") {
      this.navButton$.attr("aria-expanded", "false");
      this.navMenu$.trigger('nav-closed');
      this.setInert();
    } else {
      this.navButton$.attr("aria-expanded", "true");
      this.navMenu$.trigger('nav-opened');
      this.unsetInert();

      // Have to delay because of inert
      setTimeout(() => {
        $('.nav-link').trigger('focus');
      }, 100);
    }
  }
}
